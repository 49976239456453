@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .no-spinner {
    -moz-appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}

/* body .bg-grad {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    ),
    rgb(var(--background-start-rgb));
}

.eye {
  position: absolute;
  top: 40.5%;
  right: 16%;
  cursor: pointer;
}

.scrollable-section-hide {
  /* Vertical scrolling */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.scrollable-section-hide::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}